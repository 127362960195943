<template>
  <div class="page-content">
    <breadcrumb title="entdECKungen" bgImage="/assets/bg/reiseplane.jpg" :hideOnMobile="true"></breadcrumb>

    <div class="container-fluid mt-3 mt-lg-0" v-if="hasCollections">
      <section class="section">
        <h4 class="mb-1"><b>Noch unschlüssig, wo es hingehen soll?</b></h4>
        <p class="mb-2">Entdecke Eckernförde mit unserem 360° Rundgang, bereitgestellt von <a target="_blank" href="https://linsenspektrum.de/">www.linsenspektrum.de</a></p>
        <div class="row">
          <div class="col-lg-6" v-if="!$isMobileScreen">
            <iframe src="https://www.eckernfoerde360.de/" width="100%" frameborder="0"></iframe>
          </div>
          <div class="col-lg-6 col-md-12" style="height:65vh; overflow:auto;">
            <div class="row card-row">
                <ReiseplanCard class="col-12 col-sm-5" v-for="collection in collections" :key="collection.id" v-if="collection.noOfContents>1" :collection="collection" :isSmall="false" :marginBottom="'15px'" :disableLink="true"/>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- Footer Margin -->
      <div class="footer-margin"></div>
    </div>
  </template>

  <script>   
   import { showLoader, hideLoader } from '@/utils/helpers';
   import { collections4Tripmeister } from '@/api/custom';
   import { screenSizeMixin } from '@/mixins/screenSizeMixin';

   export default {
    name: 'ReiseplanIndex',
    mixins: [screenSizeMixin],
    components: {   
      ReiseplanCard: () => import('@/components/cards/ReiseplanCard'),
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
    },
    data() {
      return {
        loader: false,
        collections: null,
      }
    },
    computed:{
      hasCollections(){
        if(this.collections!=null){
          for(var i = 0; i < this.collections.length; i++){
            if(this.collections[i].noOfContents>1){
              return true;
            }  
          }
        }
        return false;
      },
    },
    created(){
      this.getCollections();
    },
    methods: {
      showLoader,
      hideLoader,
      getCollections() {
        this.loader = this.showLoader(this.loader);
        collections4Tripmeister()
        .then(response => {
          this.collections = response.data;
        })
        .catch(error => {
          console.log(error);

          this.$notify({
            duration: 2500,
            title: 'Fehler beim Laden der Inhalte!',
            text: 'Die KulTouren konnten nicht geladen werden. Die Verbindung zum Server wurde unterbrochen.'
          });

        })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);                      
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .page-content {
    height: 100vh;
  }

  .card-row {
    @media(min-width:992px){
      justify-content: center;
    }
  }

  iframe {
    height: 65vh;
  }

  .card {
    @media(max-width:449px){
      width: 100% !important;
    }


    @include responsive($scroll-breakpoint) {
      &:first-child {
        margin-left: 0 !important;
      }
    }
  }

</style>
